<template>
    <page-title
        icon="bi-people"
        title="ユーザ新規登録"
    >
        <button-back
            :to="{name: 'UserList'}"
            text="一覧へ"
        ></button-back>
    </page-title>

    <section class="section">
        <form @submit.prevent="save">
            <div class="form-group col-md-10 mb-3">
                <label>アカウントタイプ</label>
                <div class="form-control bg-light">{{ user.account_type_display }}</div>
            </div>
            <div class="form-group col-md-10 mb-3">
                <label>氏名</label>
                <form-input
                    v-model="user.user_name"
                    :required="true"
                    maxlength="20"
                ></form-input>
                <p class="text-secondary">＊20文字以内</p>
            </div>
            <div class="form-group col-md-10 mb-3">
                <label>ログインID</label>
                <form-input
                    v-model="user.login_key"
                    :required="true"
                    maxlength="20"
                ></form-input>
                <p class="text-secondary">＊20文字以内</p>
            </div>
            <div class="form-group col-md-10 mb-3">
                <label>パスワード</label>
                <form-input-password
                    v-model="user.password"
                    :required="true"
                    pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,1000}$"
                ></form-input-password>
                <p class="text-secondary">＊大文字を含む英数字8文字以上</p>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>ログイン可否</label>
                <form-select
                    v-model="user.is_available"
                    :options="is_available"
                    empty_option="-- 選択 --"
                    :required="true"
                ></form-select>
            </div>
            <div class="form-group col-md-8 mb-4">
                <label>権限</label>
                <form-checkbox-buttons
                    v-model="user.roles"
                    :options="roles"
                    optional_label_class="border-1"
                ></form-checkbox-buttons>
            </div>
            <div class="col-md-4">
                <button-exec-create
                    size="btn-lg"
                ></button-exec-create>
            </div>
        </form>
    </section>
</template>

<script>
import User from '@/models/entities/user'
import AccountType from '@/models/enums/account-type';
import IsAvailable from '@/models/enums/is-available';
import Role from '@/models/enums/role';
import PageTitle from '@/components/PageTitle.vue';
import ButtonBack from '@/components/buttons/ButtonBack';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate';
import FormCheckboxButtons from '@/components/forms/FormCheckboxButtons';
import FormInput from '@/components/forms/FormInput';
import FormInputPassword from '@/components/forms/FormInputPassword';
import FormSelect from '@/components/forms/FormSelect';

export default {
    name: 'UserAdd',
    components: {
        PageTitle,
        ButtonBack,
        ButtonExecCreate,
        FormCheckboxButtons,
        FormInput,
        FormInputPassword,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            //選択肢
            account_types: AccountType.options(),
            is_available: IsAvailable.options(),
            roles: Role.options(),
            AccountType: AccountType,

            //送信する値
            user: new User(),
        }
    },
    mounted() {
        //登録できるのはアカウントタイプがID/パスワードのユーザーだけ
        this.user.account_type = AccountType.ID_PASSWORD;
    },
    methods: {
        save() {
            this.startScreenLoading();
            this.$http.post('/user', this.user)
            .then(response => {
                this.showMessage('登録しました');
                this.$router.push({name: 'UserEdit', params: {id: response.data.user_id}});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
