<template>
    <div class="btn-group-vertical d-block" role="group">
        <template v-for="(option, i) in options" :key="i">
            <input
                v-model="checkedOptions"
                type="checkbox"
                class="btn-check"
                :id="'option' + i"
                :value="option[option_key]"
                autocomplete="off"
                @change="$emit('update:modelValue', this.checkedOptions)"
                true-value="option[option_key]"
            >
            <label
                class="btn btn-outline-dark"
                :class="optional_label_class"
                :for="'option' + i"
            >
                {{ option[option_label] }}
            </label>
        </template>
    </div>
</template>

<script>
export default {
    name: 'FormCheckboxButtons',
    props: {
        modelValue: {
            type: Array
        },
        options: {
            type: Array
        },
        option_key: {
            type: String,
            default: 'key'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        optional_label_class: {
            type: String,
            default: ''
        },
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            checkedOptions: this.modelValue,
        }
    },
    watch: {
        modelValue() {
            this.checkedOptions = this.modelValue;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
